<template>
    <base-section id="info-alt" space="56">
        <v-container>
            <v-row justify="space-between">
                <v-col cols="12" md="5">
                    <base-business-info title="KEEP IN TOUCH WITH US">
                        We are at your reach, you can contact us or even visit our physical shop for more information
                        You can also reach us through our various agents
                    </base-business-info>
                </v-col>

                <v-col cols="12" md="6">
                    <base-contact-form title="MAIL US YOUR MESSAGE" />
                </v-col>
            </v-row>
        </v-container>
    </base-section>
</template>

<script>
export default {
    name: 'SectionKeepInTouch',
};
</script>
